import $ from 'jquery';
import 'jquery-validation';

// поменять сообщения для всех форм евер
// $.validator.messages.email = 'need email wtf?';

// регулярочка для валидации
$.validator.methods.email = function(value, element) {
  return this.optional(element) || /^\S+@\S+\.\S+$/.test(value);
}

var emailSubscriptonForm = (function () {
  var form;
  var inputs;
  var submitButton;
  function validateForm () {
      form.validate({
          rules: {
              email: {
                  required: true,
                  email: true,
              }
          },
          messages: {
              email: {
                // required: 'Для того, чтобы подписаться на рассылку обязательно заполните поле email',
                required: 'Напишите email в формате name@domain.ru',
                email: 'Напишите email в формате name@domain.ru',
              }
          },
          // Если сообщения об ошибках не нужны
          // errorPlacement: function (error, element) {
          //     return true;
          // },
          submitHandler: function (form) {
              $.ajax({
                  url: $(form).attr('action'),
                  type: 'POST',
                  dataType: 'json',
                  data: $(form).serialize(),
                  beforeSend: function () {
                      $(form).find('input[type="submit"]').prop('disabled', true);
                  },
                  complete: function () {
                      $(form).find('input[type="submit"]').prop('disabled', false);
                  }
                })
                .done(function (data) {
                //   form.validate().resetForm();
                  if (data.success_message) {
                    $(form).find('.form-subs-message').text(data.success_message);
                  } 
                //   else {
                    // $(form).find('.form-subs-message').text(data.errors);
                //   }
              });
          }
      });
  }

  function stateButton () {
      if (form.valid()) {
          submitButton.removeClass('btn-secondary');
          submitButton.removeAttr('disabled');
      } else {
          submitButton.addClass('btn-secondary');
          submitButton.attr('disabled', 'disabled');
      }
  }

  // public
  return {
      init: function () {
          form = $('.footer-form-subscription');
          inputs = form.find('.form-control');
          submitButton = form.find('.btn');
          validateForm();
          this.bindEvents();
      },
      bindEvents: function () {
          inputs.on('change keyup keydowm', inputs, function (event) {
              event.preventDefault();
              stateButton();
          });
      }
  };
}());

var emailSubscriptonFormAccordion = (function () {
    var form;
    var inputs;
    var submitButton;
    function validateForm () {
        form.validate({
            rules: {
                email: {
                    required: true,
                    email: true,
                }
            },
            messages: {
                email: {
                  // required: 'Для того, чтобы подписаться на рассылку обязательно заполните поле email',
                  required: 'Напишите email в формате name@domain.ru',
                  email: 'Напишите email в формате name@domain.ru',
                }
            },
            submitHandler: function (form) {
                $.ajax({
                    url: $(form).attr('action'),
                    type: 'POST',
                    dataType: 'json',
                    data: $(form).serialize(),
                    beforeSend: function (data) {
                        $(form).find('input[type="submit"]').prop('disabled', true);
                    },
                    complete: function (data) {
                        $(form).find('input[type="submit"]').prop('disabled', false);
                    }
                })
                .done(function (data) {
                  //   form.validate().resetForm();
                    if (data.success_message) {
                      $(form).find('.form-subs-message').text(data.success_message);
                    } 
                  //   else {
                      // $(form).find('.form-subs-message').text(data.errors);
                  //   }
                });
            }
        });
    }
  
    function stateButton () {
        if (form.valid()) {
            submitButton.removeClass('btn-secondary');
            submitButton.removeAttr('disabled');
        } else {
            submitButton.addClass('btn-secondary');
            submitButton.attr('disabled', 'disabled');
        }
    }
  
    // public
    return {
        init: function () {
            form = $('.footer-form-subscription--accordion');
            inputs = form.find('.form-control');
            submitButton = form.find('.btn');
            validateForm();
            this.bindEvents();
        },
        bindEvents: function () {
            inputs.on('change keyup keydowm', inputs, function (event) {
                event.preventDefault();
                stateButton();
            });
        }
    };
}());

var cartForm = (function () {
    var form;
    var inputs;
    var submitButton;
    function validateForm () {
        form.validate({
            rules: {
                name: {
                    required: true,
                },
                tel: {
                    required: true,
                }
            },
            // Если сообщения об ошибках не нужны
            errorPlacement: function (error, element) {
                return true;
            },
            // messages: {
            //     email: {
            //         // required: 'Для того, чтобы подписаться на рассылку обязательно заполните поле email',
            //         required: 'Напишите email в формате name@domain.ru',
            //         email: 'Напишите email в формате name@domain.ru',
            //     }
            // },
            // submitHandler: function (form) {
            //     $.ajax({
            //         url: $(form).attr('action'),
            //         type: 'POST',
            //         dataType: 'json',
            //         data: $(form).serialize(),
            //         beforeSend: function (data) {
            //             $(form).find('input[type="submit"]').prop('disabled', true);
            //         },
            //         complete: function (data) {
            //             $(form).find('input[type="submit"]').prop('disabled', false);
            //             // setTimeout(window.location.href = '/', 1000);
            //         }
            //     })
            //     .done(function (data) {
            //         //   form.validate().resetForm();
            //         if (data.success_message) {
            //             $(form).find('.form-subs-message').text(data.success_message);
            //         }
            //     });
            // }
        });
    }

    function stateButton () {
        if (form.valid()) {
            submitButton.removeClass('btn-secondary');
            submitButton.removeAttr('disabled');
        } else {
            submitButton.addClass('btn-secondary');
            submitButton.attr('disabled', 'disabled');
        }
    }

    // public
    return {
        init: function () {
            form = $('#cart-form');
            inputs = form.find('.cart-form-input');
            submitButton = form.find('.cart-form-input--submit');
            validateForm();
            this.bindEvents();
        },
        bindEvents: function () {
            inputs.on('change keyup keydowm', inputs, function (event) {
                event.preventDefault();
                stateButton();
            });
        }
    };
}());

// Инициализация и скрипты
$(function () {

    if ($('.footer-form-subscription').length > 0) {
        $(this).find('.btn').click(function() {
          emailSubscriptonForm.init();
        })
    }

    if ($('.footer-form-subscription--accordion').length > 0) {
        $(this).find('.btn').click(function() {
          emailSubscriptonFormAccordion.init();
        })
    }

    if ($('#cart-form').length > 0) {
        cartForm.init();
    }

    $(function(){
        $('#cart-form input.cart-form-input--tel').mask("+7 (999) 999-9999");
    });
});