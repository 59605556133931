import lightGallery from 'lightgallery';

document.addEventListener('DOMContentLoaded', () => {

  const galleryInArtistPage = document.getElementById('gallery-artist-page');

  lightGallery(galleryInArtistPage, {
    selector: '.card-image--wrapper',
    licenseKey: '0000-0000-0000-0000',
    speed: 500
  });

  const galleryInBannerHeaderPage = document.getElementById('gallery-banner-header-page');

  lightGallery(galleryInBannerHeaderPage, {
    selector: '.about-content-banner-image',
    licenseKey: '0000-0000-0000-0000',
    speed: 500
  });

  const galleryWithHiddenImages = document.getElementById('gallery-with-hidden-images');

  lightGallery(galleryWithHiddenImages, {
    selector: '.about-content-banner-image',
    licenseKey: '0000-0000-0000-0000',
    speed: 500
  });

  const galleryShopItemImages = document.getElementById('shop-item-block-slider');

  lightGallery(galleryShopItemImages, {
    selector: '.shop-item-image',
    licenseKey: '0000-0000-0000-0000',
    speed: 500
  });
});