document.addEventListener('DOMContentLoaded', () => {
    if (window.location.href.indexOf('admin')) {

        const main = document.querySelector('#main');
        const footer = document.querySelector('#footer');
        const toolbar = document.querySelector('#toolbar');
        const contentMain = document.querySelector('#content-main');

        // скрытие тулбара с поиском
        if (toolbar) {
            toolbar.style.display = 'none';
        }

        // скрытие обжект тула в контент мейн
        if (contentMain) {
            const objectToolsContentMain = contentMain.querySelector('.object-tools');
            console.log('contentMain');
            if (objectToolsContentMain) {
                console.log('contentMain + objectToolsContentMain');
                objectToolsContentMain.style.display = 'none';
                console.log('contentMain + objectToolsContentMain + d-none');
            }
        }

        // Добавление на главную блока с информацией
        if (window.location.href.indexOf('admin') && document.querySelector('.colMS')) {

            if (footer) {
                footer.style.display = 'none';
            }

            const createDiv = document.createElement('div');
            createDiv.classList.add('main-info');

            const createTitle = document.createElement('div');
            const createSubTitle = document.createElement('div');
            const createArrowWrapper = document.createElement('div');
            const createArrow = document.createElement('div');
            createTitle.classList.add('main-info-h1');
            createSubTitle.classList.add('main-info-h2');
            createArrowWrapper.classList.add('main-info-arrow--wrapper');
            createArrow.classList.add('main-info-arrow');

            createTitle.textContent = 'Привет!';
            createSubTitle.textContent = 'Нажми на раздел для начала работы с ним';

            main.append(createDiv);
            createDiv.append(createTitle);
            createDiv.append(createSubTitle);
            createDiv.append(createArrowWrapper);
            createArrowWrapper.append(createArrow);
        }

        let titleAboveImageListItem = document.querySelectorAll('.original p');

        if (titleAboveImageListItem) {
            titleAboveImageListItem.forEach(function(el) {
                el.style.display = 'none';
            })
        }
    }
});