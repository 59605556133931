import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {

  function themePage() {
    const logoHeader = document.querySelector('.header .logo');
    const logoHeaderMobile = document.querySelector('.header--mobile .logo');
    const logoFooter = document.querySelector('.footer .logo-full');
    const header = document.querySelector('.header');

    if (document.querySelector('body').classList.contains('dark-theme')) {
      logoHeader.classList.remove('logo--dark');
      logoHeader.classList.add('logo--white');
      logoHeaderMobile.classList.remove('logo--dark');
      logoHeaderMobile.classList.add('logo--white');
      logoFooter.classList.remove('logo-full--dark');
      logoFooter.classList.add('logo-full--white');
    } else if (document.querySelector('body').classList.contains('gray-theme')) {
      header.classList.add('header--border');
    }
  }
  themePage();

  const mainSlider = new Swiper(".slider-main", {
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
  });

  const mainNewsSlider = new Swiper(".slider-news, .slider-shop", {
    slidesPerView: "auto",
    spaceBetween: 10,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        spaceBetween: 20
      },
      1040: {
        spaceBetween: 30
      },
      1340: {
        spaceBetween: 60
      }
    }
  });

  const shopItemSlider = new Swiper(".slider-shop-item", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // const mainShopSlider = new Swiper(".slider-shop", {
  //   slidesPerView: "auto",
  //   spaceBetween: 10,
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //     draggable: true,
  //   },
  //   navigation: {
  //     nextEl: ".swiper-button-next",
  //     prevEl: ".swiper-button-prev",
  //   },
  //   breakpoints: {
  //     680: {
  //       slidesPerView: 2,
  //       spaceBetween: 20
  //     },
  //     1040: {
  //       slidesPerView: 3,
  //       spaceBetween: 30
  //     },
  //     1340: {
  //       slidesPerView: 4,
  //       spaceBetween: 60
  //     }
  //   }
  // });

  function initAccordion(elem, option){
    document.addEventListener('click', function (e) {
        if (!e.target.matches(elem+' .a-btn')) return;
        else{
            if(!e.target.parentElement.classList.contains('active')){
                if(option==true){
                    var elementList = document.querySelectorAll(elem+' .a-container');
                    Array.prototype.forEach.call(elementList, function (e) {
                        e.classList.remove('active');
                    });
                }            
                e.target.parentElement.classList.add('active');
            }else{
                e.target.parentElement.classList.remove('active');
            }
        }
    });
  }
  initAccordion('.accordion.accordion--footer-info', false);


  function toTop(){
    document.querySelector('.footer-to-top').addEventListener('click', function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
  if (document.querySelector('.footer-to-top')) {
    toTop();
  }

  function openMenu(){
    let header = document.querySelector('.header');
    let mobileMenu = document.querySelector('.mobile-menu');
    let burgerMenu = document.querySelector('.burger-menu');
    let shopcartBtn = document.querySelector('.header--mobile .shopcart');
    let searchBtnMobile = document.querySelector('.search--mobile');

    burgerMenu.addEventListener('click', function () {
      // смена иконки путем добавления .active
      this.classList.toggle('active');

      // открывание и закрывание мобильного меню
      mobileMenu.classList.toggle('mobile-menu--visible');

      // Добавление класса с бордером к хедеру
      if (document.querySelector('body').classList.contains('dark-theme')) {
        header.classList.toggle('header--border');
      }

      // переключение корзины и поиска при окрытом мобильном меню
      if (shopcartBtn.style.display != 'none') {
        shopcartBtn.style.display = 'none';
        searchBtnMobile.style.display = 'block';
      } else if (shopcartBtn.style.display == 'none') {
        shopcartBtn.style.display = 'block';
        searchBtnMobile.style.display = 'none';
      }
    });
  }

  if(document.querySelector('.burger-menu')) {
    openMenu();
  }

  function openSearch(){
    let header = document.querySelector('.header');
    let searchBtn = header.querySelector('.search');
    let searchBtnMobile = header.querySelector('.search--mobile');
    let searchBlock = document.querySelector('.search-block');
    let burgerMenu = document.querySelector('.burger-menu');
    let burgerMenuArrow = document.querySelector('.burger-menu--arrow');
    let closeSearchBtn = document.querySelector('.search-block-close');

    searchBtn.addEventListener('click', function () {
      searchBlock.classList.toggle('search-block--visible');
    });

    closeSearchBtn.addEventListener('click', function () {
      searchBlock.classList.toggle('search-block--visible');
    });

    searchBtnMobile.addEventListener('click', function () {
      searchBlock.classList.toggle('search-block--visible');

      // смена иконки .burger-menu--arrow путем добавления
      burgerMenuArrow.classList.toggle('burger-menu--arrow--visible');
      burgerMenu.classList.toggle('burger-menu--invisible');
    });

    burgerMenuArrow.addEventListener('click', function () {
      searchBlock.classList.toggle('search-block--visible');

      // смена иконки .burger-menu путем добавления класса
      burgerMenuArrow.classList.toggle('burger-menu--arrow--visible');
      burgerMenu.classList.toggle('burger-menu--invisible');
    });
  }

  if(document.querySelector('.header')) {
    openSearch();
  }

  function langSwitchText() {
    const langBtn = document.querySelectorAll('.lang');

    langBtn.forEach(function (elem) {
      const langName = elem.getAttribute('data-lang');
      elem.textContent = langName;

      if (elem.closest('.mobile-menu')) {
        if (langName == 'en') {
          elem.textContent = 'english version';
        } else if (langName == 'ru') {
          elem.textContent = 'russian version';
        }
      }
    })
  }

  if(document.querySelector('.lang')) {
    langSwitchText();
  }

  function addActiveToMenu() {
    const url = window.location.href;
    
    if (url.indexOf('exhibition') !== -1) {
      document.querySelector('nav li:nth-child(2) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(2)').classList.add('active');
    } else if (url.indexOf('artist') !== -1) {
      document.querySelector('nav li:nth-child(3) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(3)').classList.add('active');
    } else if (url.indexOf('news') !== -1) {
      document.querySelector('nav li:nth-child(4) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(4)').classList.add('active');
    } else if (url.indexOf('about') !== -1) {
      document.querySelector('nav li:nth-child(5) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(5)').classList.add('active');
    } else if (url.indexOf('goods') !== -1) {
      document.querySelector('nav li:nth-child(6) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(6)').classList.add('active');
    } else if (url.indexOf('cart') !== -1) {
      document.querySelectorAll('nav li a').forEach(function (link) {
        link.classList.remove('active');
      })
      document.querySelectorAll('.mobile-menu .mobile-menu-item').forEach(function (linkMobile) {
        linkMobile.classList.remove('active');
      })
    } else {
      document.querySelector('nav li:nth-child(1) a').classList.add('active');
      document.querySelector('.mobile-menu .mobile-menu-item:nth-child(1)').classList.add('active');
    }
  }

  if(document.querySelector('.header')) {
    addActiveToMenu();
  }

  window.addEventListener('resize', function() {
    let innerWidthWindow = window.innerWidth;
    let mobileHeader = document.querySelector('.header--mobile');

    if (mobileHeader) {
      let mobileHeaderStyles = window.getComputedStyle(mobileHeader);
      let mobileHeaderDisplay = mobileHeaderStyles.getPropertyValue('display');
      let burgerMenuBtn = document.querySelector('.burger-menu');

      if(innerWidthWindow < 980 && mobileHeaderDisplay == 'flex') {
        if(burgerMenuBtn.classList.contains('active')) {
          burgerMenuBtn.click();
        }
      }
    }
  });
});