document.addEventListener('DOMContentLoaded', () => {
  let formBlock = document.querySelectorAll('form');

  formBlock.forEach(function(form) {
    let formBlockSubmit = form.querySelector('.btn');
    let formBlockClear = form.querySelector('.form-input--clear');
    let formBlockInputWithClear = form.querySelector('.solo-input');
    let formBlockMessage = form.querySelector('.form-subs-message');

    if(formBlockInputWithClear) {
      let eventInput = new InputEvent('input');

      formBlockInputWithClear.addEventListener('input', function() {
        if (formBlockInputWithClear.value == formBlockInputWithClear.defaultValue) {
          formBlockSubmit.classList.add('btn--disabled');
          formBlockSubmit.setAttribute('disabled', 'disabled');
          formBlockClear.classList.remove('input-on-focus');
        } else {
          formBlockSubmit.classList.remove('btn--disabled');
          formBlockSubmit.removeAttribute('disabled');
          formBlockClear.classList.add('input-on-focus');
        }
      });

      formBlockClear.addEventListener('click', function() {
        formBlockInputWithClear.value = formBlockInputWithClear.defaultValue;
        formBlockInputWithClear.dispatchEvent(eventInput);
        formBlockMessage.textContent = '';
      });
    }
  });
});